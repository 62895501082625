const VARIABLES = {
  ROLES: {
    TYPE: {
      super_admin: 1,
      country_admin: 2,
      trader: 3,
      manager: 4,
    },
    INVERSE_TYPE: {
      1: "super_admin",
      2: "country_admin",
      3: "trader",
      4: "manager",
    },
  },
  PERMISSIONS: {
    TYPE: {
      canBuy: "can_buy",
      canSell: "can_sell",
      canSeeCarValue: "can_see_carvalue",
      canSeeCarServiceModule: "can_see_carservice_module",
    },
    INVERSE_TYPE: {
      can_buy: "canBuy",
      can_sell: "canSell",
      can_see_carvalue: "canSeeCarValue",
      can_see_carservice_module: "canSeeCarServiceModule",
    },
  },
  STATUSES: [
    {
      value: "new",
      code: "new",
    },
    {
      value: "active",
      code: "active",
    },
    {
      value: "inactive",
      code: "inactive",
    },
    {
      value: "blocked",
      code: "blocked",
    },
    {
      value: "deleted",
      code: "deleted",
    },
  ],
  SELLERS: [
    {
      value: "Eladó 1",
      code: "Eladó 1",
      active: true,
    },
    {
      value: "Eladó 2",
      code: "Eladó 2",
      active: true,
    },
    {
      value: "Eladó 3",
      code: "Eladó 3",
      active: true,
    },
    {
      value: "Eladó 4",
      code: "Eladó 4",
      active: true,
    },
    {
      value: "Eladó 5",
      code: "Eladó 5",
      active: true,
    },
  ],
  LANGUAGES: [
    {
      value: 1,
      code: "en",
      active: true,
    },
    {
      value: 2,
      code: "hu",
      active: true,
    },
    {
      value: 3,
      code: "de",
      active: true,
    },
    {
      value: 4,
      code: "fr",
      active: true,
    },
    {
      value: 5,
      code: "it",
      active: false,
    },
    {
      value: 6,
      code: "gr",
      active: true,
    },
    {
      value: 7,
      code: "es",
      active: false,
    },
    {
      value: 8,
      code: "ru",
      active: false,
    },
    {
      value: 9,
      code: "cn",
      active: false,
    },
    {
      value: 12,
      code: "sl",
      active: true,
    },
  ],
  LANGUAGES_VALUE_BY_CODE: {
    en: 1,
    hu: 2,
    de: 3,
    fr: 4,
    it: 5,
    gr: 6,
    es: 7,
    ru: 8,
    cn: 9,
    sl: 12,
  },
  COUNTRY_VALUE_BY_ID: {
    1: "hu",
    2: "ch",
    3: "ro",
    4: "de",
    5: "pl",
    6: "sk",
    7: "bg",
    9: "lv",
    10: "lt",
    11: "nl",
    12: "sl",
    13: "be",
    14: "at",
    15: "fr",
    16: "et",
    17: "cz",
    41: "it",
    50: "gr",
    65: "hr",
    70: "pt",
    71: "es",
    75: "cy",
    103: "dk",
    104: "fi",
    105: "ie",
    106: "lu",
    107: "mt",
    108: "se",
    109: "is",
    110: "li",
    111: "no",
  },
  COUNTRY_VALUE_BY_CODE: {
    hu: "1",
    ch: "2",
    ro: "3",
    de: "4",
    pl: "5",
    sk: "6",
    bg: "7",
    lv: "9",
    lt: "10",
    nl: "11",
    sl: "12",
    be: "13",
    at: "14",
    fr: "15",
    et: "16",
    cz: "17",
    it: "41",
    gr: "50",
    hr: "65",
    pt: "70",
    es: "71",
    cy: "75",
    dk: "103",
    fi: "104",
    ie: "105",
    lu: "106",
    mt: "107",
    se: "108",
    is: "109",
    li: "110",
    no: "111",
  },
  COUNTRIES: [
    {
      value: 1,
      name: "hungary",
      code: "hu",
      currency: "HUF",
      active: true,
    },
    {
      value: 2,
      name: "switzerland",
      code: "ch",
      currency: "CHF",
      active: true,
    },
    {
      value: 3,
      name: "romania",
      code: "ro",
      currency: "RON",
      active: false,
    },
    {
      value: 4,
      name: "deutschland",
      code: "de",
      currency: "EUR",
      active: false,
    },
    {
      value: 5,
      name: "poland",
      code: "pl",
      currency: "PLN",
      active: false,
    },
    {
      value: 6,
      name: "slovakia",
      code: "sk",
      currency: "EUR",
      active: true,
    },
    {
      value: 7,
      name: "bulgaria",
      code: "bg",
      currency: "BGN",
      active: false,
    },
    {
      value: 9,
      name: "latvia",
      code: "lv",
      currency: "EUR",
      active: false,
    },
    {
      value: 10,
      name: "lithuania",
      code: "lt",
      currency: "EUR",
      active: false,
    },
    {
      value: 11,
      name: "netherlands",
      code: "nl",
      currency: "EUR",
      active: false,
    },
    {
      value: 12,
      name: "slovenia",
      code: "sl",
      currency: "EUR",
      active: false,
    },
    {
      value: 13,
      name: "belgium",
      code: "be",
      currency: "EUR",
      active: false,
    },
    {
      value: 14,
      name: "austria",
      code: "at",
      currency: "EUR",
      active: true,
    },
    {
      value: 15,
      name: "france",
      code: "fr",
      currency: "EUR",
      active: false,
    },
    {
      value: 16,
      name: "estonia",
      code: "et",
      currency: "EUR",
      active: true,
    },
    {
      value: 17,
      name: "czech_republic",
      code: "cz",
      currency: "CZK",
      active: true,
    },
    {
      value: 41,
      name: "italy",
      code: "it",
      currency: "EUR",
      active: true,
    },
    {
      value: 50,
      name: "greece",
      code: "gr",
      currency: "EUR",
      active: true,
    },
    {
      value: 65,
      name: "HR",
      code: "hr",
      currency: "EUR",
      active: false,
    },
    {
      value: 70,
      name: "portuguese",
      code: "pt",
      currency: "EUR",
      active: false,
    },
    {
      value: 71,
      name: "spanish",
      code: "es",
      currency: "EUR",
      active: false,
    },
    {
      value: 75,
      name: "cyprus",
      code: "cy",
      currency: "EUR",
      active: false,
    },
    {
      value: 103,
      name: "denmark",
      code: "dk",
      currency: "DKK",
      active: false,
    },
    {
      value: 104,
      name: "finland",
      code: "fi",
      currency: "EUR",
      active: false,
    },
    {
      value: 105,
      name: "ireland",
      code: "ie",
      currency: "EUR",
      active: false,
    },
    {
      value: 106,
      name: "luxembourg",
      code: "lu",
      currency: "EUR",
      active: false,
    },
    {
      value: 107,
      name: "malta",
      code: "mt",
      currency: "EUR",
      active: false,
    },
    {
      value: 108,
      name: "sweden",
      code: "se",
      currency: "SEK",
      active: false,
    },
    {
      value: 109,
      name: "iceland",
      code: "is",
      currency: "ISK",
      active: false,
    },
    {
      value: 110,
      name: "liechtenstein",
      code: "li",
      currency: "CHF",
      active: false,
    },
    {
      value: 111,
      name: "norway",
      code: "no",
      currency: "NOK",
      active: false,
    },
  ],
  FUELS: [
    {
      id: "petrol",
      name: "petrol",
      key: "petrol",
      value: "petrol",
    },
    {
      id: "diesel",
      name: "diesel",
      key: "diesel",
      value: "diesel",
    },
    {
      id: "hybrid",
      name: "hybrid",
      key: "hybrid",
      value: "hybrid",
    },
    {
      id: "electronic",
      name: "electronic",
      key: "electronic",
      value: "electronic",
    },
    {
      id: "gas",
      name: "gas",
      key: "gas",
      value: "gas",
    },
    {
      id: "cng",
      name: "cng",
      key: "cng",
      value: "cng",
    },
    {
      id: "lpg",
      name: "lpg",
      key: "lpg",
      value: "lpg",
    },
    {
      id: "other",
      name: "other",
      key: "other",
      value: "other",
    },
  ],
  POWER_UNITS: [
    {
      id: "hp",
      name: "HP",
      key: "HP",
      value: "HP",
    },
    {
      id: "kw",
      name: "kW",
      key: "kW",
      value: "kW",
    },
    {
      id: "HP",
      name: "HP",
      key: "HP",
      value: "HP",
    },
    {
      id: "kW",
      name: "kW",
      key: "kW",
      value: "kW",
    },
  ],
  USED_DISTANCE_UNITS: [
    {
      id: "km",
      name: "KM",
      key: "KM",
      value: "KM",
    },
    {
      id: "mi",
      name: "MI",
      key: "MI",
      value: "MI",
    },
    {
      id: "KM",
      name: "KM",
      key: "KM",
      value: "KM",
    },
    {
      id: "MI",
      name: "MI",
      key: "MI",
      value: "MI",
    },
    {
      id: "operating_hours",
      name: "operating_hours",
      key: "operating_hours",
      value: "operating_hours",
    },
  ],
  BATTERY_CAPACITY_UNITS: [
    {
      id: "kWh",
      name: "kWh",
      key: "kwh",
      value: "kWh",
    },
    {
      id: "Ah",
      name: "Ah",
      key: "Ah",
      value: "Ah",
    },
  ],
  GEARBOX: [
    {
      id: "manual",
      name: "manual",
      key: "manual",
      value: "manual",
    },
    {
      id: "automatic",
      name: "automatic",
      key: "automatic",
      value: "automatic",
    },
  ],
  VEHICLE_CONDITIONS: [
    {
      id: "driveable",
      name: "driveable",
      key: "driveable",
      value: "driveable",
    },
    {
      id: "trailable",
      name: "trailable",
      key: "trailable",
      value: "trailable",
    },
    {
      id: "in_pieces",
      name: "in_pieces",
      key: "in_pieces",
      value: "in_pieces",
    },
    {
      id: "dont_driveable",
      name: "dont_driveable",
      key: "dont_driveable",
      value: "dont_driveable",
    },
  ],
  VEHICLE_TYPES_BY_ID: {
    1: "car",
    2: "transporter",
    3: "motorcycle",
    4: "truck",
    5: "bus",
    6: "other",
    57: "car",
  },
  EXCHANGE_BY_ID: {
    1: "can_see_carservice_module",
    2: "can_see_carvalue",
  },
  EXCHANGE_BY_VALUE: {
    can_see_carservice_module: "1",
    can_see_carvalue: "2",
  },
  REPORT_PARAMETERS: [
    {
      name: "company_name",
      id: 1,
      value: 1,
    },
    {
      name: "vehicle_code",
      id: 2,
      value: 2,
    },
    {
      name: "vehicle_model_id",
      id: 3,
      value: 3,
    },
    {
      name: "vehicle_type_id",
      id: 4,
      value: 4,
    },
    {
      name: "damage_no",
      id: 5,
      value: 5,
    },
    {
      name: "first_registration_global",
      id: 6,
      value: 6,
    },
    {
      name: "vin_code",
      id: 7,
      value: 7,
    },
    {
      name: "start_date",
      id: 8,
      value: 8,
    },
    {
      name: "expire_date",
      id: 9,
      value: 9,
    },
    {
      name: "bid_number",
      id: 10,
      value: 10,
    },
    {
      name: "highest_bid",
      id: 11,
      value: 11,
    },
    {
      name: "currency",
      id: 12,
      value: 12,
    },
    {
      name: "highest_bidder",
      id: 13,
      value: 13,
    },
    {
      name: "estimated_damage_price",
      id: 14,
      value: 14,
    },
    {
      name: "damage_price",
      id: 15,
      value: 15,
    },
    {
      name: "licence_plate_number",
      id: 16,
      value: 16,
    },
    {
      name: "used_distance",
      id: 17,
      value: 17,
    },
    {
      name: "model_year",
      id: 18,
      value: 18,
    },
  ],
};

export default VARIABLES;
